@use "global" as *;
@use "_newyear2024.scss";

.index {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.index-section {
  position: relative;
  overflow: hidden;
}

.index-catch {
  display: flex;
  height: 100svh;
  @include media(pc) {
    align-items: center;
  }
  
  @include media(sp) {
    align-items: flex-end;
    padding-bottom: sp(30);
  }
}

.index-catch_container {
  @include media(pc) {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 100px;
  }
  
  @include media(sp) {
    padding-left: sp(30);
    padding-right: sp(30);
  }
}

.index-catch_symbol {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: -1;
  width: 100%;
  height: 100%;
  @include media(pc) {

  }
  @include media(1600px) {
    right: -200px;
  }
  @include media(1500px) {
    right: -300px;
  }
  @include media(1400px) {
    right: -400px;
  }

  @include media(sp) {

  }
}

.index-catch_symbol_upper {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, -100%);
  @include media(pc) {
    height: 50svh;
  }
  
  @include media(sp) {
    height: 80vw;
  }

  &.aos-animate {
    transform: translate(0);
  }
}

.index-catch_symbol_upper_item {
  width: 100%;
  height: 100%;
  
  @include media(pc) {
  }
  
  @include media(sp) {
  
  }
}

.index-catch_symbol_lower {
  position: absolute;
  right: 0;
  transform: translate(100%, 100%);
  
  @include media(pc) {
    height: 50svh;
    bottom: 0;
  }
  
  @include media(sp) {
    height: 41.5vw;
    top: 80vw;
  }

  &.aos-animate {
    transform: translate(0);
  }
}

.index-catch_symbol_lower_item {
  width: 100%;
  height: 100%;

  @include media(pc) {
  }
  
  @include media(sp) {
  
  }
}

.index-catch_messageEn {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  line-height: 1.4;
  @include media(pc) {
    font-size: 65px;
    letter-spacing: .5rem;
  }
  
  @include media(sp) {
    font-size: sp(30);
    letter-spacing: .2rem;
  }

}

.index-catch_messageJp {
  line-height: 2;
  @include media(pc) {
    font-size: 18px;
    margin-top: 20px;
    letter-spacing: .3rem;
    font-weight: 700;
  }
  
  @include media(sp) {
    letter-spacing: .1rem;
    font-size: sp(14);
    font-weight: 500;
  }

}

.index-catch_messageJp_br {
  @include media(972px, min) {
    display: none;
  }

  @include media(971px, max) {
    display: block;
  }
}

.index-catch_news {
  
  @include media(pc) {
    margin-top: 150px;
  }
  
  @include media(sp) {
    margin-top: sp(30);
  }

}

.index-catch_news_item {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.7;
  @include media(pc) {
    font-size: 16px;
  }
  
  @include media(sp) {
    font-size: sp(14);
  }

  & + & {
    @include media(pc) {
      margin-top: 20px;
    }
    
    @include media(sp) {
      margin-top: sp(15);
    }
  }

  &:last-child {
    @include media(pc) {

    }

    @include media(sp) {
      width: 100%;
    }
  }
}

.index-catch_news_category {
  font-family: 'Josefin Sans', sans-serif;
  color: #fff;
  background-color: #000;
  padding: .4rem 1rem .1rem;
  align-self: center;
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.index-catch_news_time {
  color: #959595;
  margin-right: 2rem;
  padding: .5rem 0;
  @include media(pc) {
    font-size: 17px;
    margin-left: 2rem;
  }
  
  @include media(sp) {
    margin-left: 1rem;
  }
}

.index-catch_news_title {
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: relative;

  @include media(pc) {

  }
  
  @include media(sp) {
    margin-top: sp(5);
  }
}

.index-purpose {
  display: flex;
  align-items: center;

  background-color: #f7f7f7;
  
  @include media(pc) {
    height: 100vh;
  }
  
  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(50);
  }
}

.index-purpose_container {
  @include media(pc) {
    padding-left: 10vw;
    padding-right: 10vw;
  
  }
  
  @include media(sp) {
    padding-left: sp(30);
    padding-right: sp(30);
  }
}

.index-purpose_message {
  font-weight: 700;
  letter-spacing: .05rem;
  line-height: 1.9;
  
  @include media(pc) {
    font-size: 40px;
  }
  
  @include media(sp) {
    font-size: sp(22);
  }


}

.index-purpose_body {
  font-weight: 700;
  
  @include media(pc) {
    margin-top: 40px;
    font-size: 20px;
    line-height: 2.6;
  }
  
  @include media(sp) {
    font-weight: 500;
    line-height: 2;
    font-size: sp(15);
    margin-top: sp(20);
  }


}


.index-support {
  display: flex;
  color: #fff;
  background-color: #000;
  
  @include media(pc) {
    height: 100vh;
  }
  
  @include media(sp) {
  
  }
}

.index-support_container {
  @include media(pc) {
    display: flex;
  }
  
  @include media(sp) {
  
  }
}

.index-support_first {
  display: flex;
  align-items: center;
  background-image: url('/_/media/page/index/support_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  filter: grayscale(100%);

  @include media(pc) {
    padding-left: 5vw;
    padding-right: 5vw;
    width: 50%;
  }
  
  @include media(sp) {
    height: sp(240);
  
  }

  &.aos-animate {
    filter: grayscale(0);

    @include media(pc) {

    }

    @include media(sp) {

    }
  }
}

.index-support_message {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  letter-spacing: .2rem;
  line-height: 1.3;
  vertical-align: middle;
  
  @include media(pc) {
    font-size: 56px;
  }
  @include media(1366px) {
    font-size: 40px;
  }
  @include media(1000px) {
    font-size: 30px;
  }
  
  @include media(sp) {
    font-size: sp(30);
  }
}

.index-support_message_collab {
  display: inline-block;
  font-weight: 200;
  transform: translateY(-8%);
  line-height: 1;
  @include media(pc) {
    font-size: 80px;
  }
  @include media(1366px) {
    font-size: 60px;
  }
  @include media(1000px) {
    font-size: 40px;
  }
  
  @include media(sp) {
    font-size: sp(50);
  }
}

.index-support_second {
  display: flex;
  align-items: center;
  
  @include media(pc) {
    padding-left: 5vw;
    padding-right: 5vw;
    width: 50%;
  }
  
  @include media(sp) {
    padding: sp(30);
  }
}

.index-support_body {
  line-height: 2.2;
  @include media(pc) {
    font-size: 26px;
  }
  @include media(1366px) {
    font-size: 20px;
  }
  @include media(1000px) {
    font-size: 16px;
  }
  
  @include media(sp) {
  
  }
}

.index-work {
  position: relative;
  
  @include media(pc) {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @include media(sp) {
  
  }
}

.index-work_container {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
    padding: sp(100) sp(30);
  }
}

.index-work_list {
  @include media(pc) {
    display: flex;
  }
  
  @include media(sp) {
  
  }
}

.index-work_item {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  
  @include media(pc) {
    font-size: 32px;
  }
  
  @include media(sp) {
    text-align: center;
    font-size: sp(30);
  }

  & + & {
    @include media(pc) {
      margin-left: 80px;
    }
    
    @include media(sp) {
      margin-top: sp(60);
    }
  }
}

.index-work_anchor {
  text-decoration: none;
  color: #000;
  position: relative;
  transition: color 0.4s;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: -.5rem;
    left: -.8rem;
    padding: .5rem .8rem 0 .8rem;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}


.index-work_anchor_text {
  position: relative;
  z-index: 1;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}


.about-philosophy {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.about-philosophy_heading {
  font-family: 'Josefin Sans', sans-serif;;
  font-weight: 700;
  letter-spacing: .2rem;
  @include media(pc) {
    font-size: 30px;
    margin-bottom: 40px;
  }
  
  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(30);
  }
}


.about-philosophy_mvv {
  
  @include media(pc) {
    margin-top: 80px;
  }
  
  @include media(sp) {
    margin-top: sp(50);
  }
}

.about-philosophy_mvv_container {
  border-top: 1px solid #ddd;
  
  @include media(pc) {
    display: flex;
  }
  
  @include media(sp) {
    padding: sp(30) sp(20) sp(20);
  }
}

.about-philosophy_mvv_term {
  font-weight: 700;
  
  @include media(pc) {
    width: 30%;
    padding: 40px 30px 70px;
  }
  
  @include media(sp) {
    display: flex;
    align-items: center;
    margin-bottom: sp(15);
  }
}

.about-philosophy_mvv_term_en {
  font-family: 'Josefin Sans',sans-serif;
  color: $color-primary;
  @include media(pc) {
    font-size: 20px;
    margin-bottom: .3rem;
    line-height: 1.6;
  }
  
  @include media(sp) {
    font-size: sp(20);
  }
}

.about-philosophy_mvv_term_ja {
  
  @include media(pc) {
    font-size: 14px;
  }
  
  @include media(sp) {
    font-size: sp(13);
    margin-left: sp(20);
  }
}

.about-philosophy_mvv_desc {
  font-weight: 700;
  @include media(pc) {
    width: 70%;
    padding: 40px 30px 70px;
    font-size: 20px;
    line-height: 2.5;
  }
  
  @include media(sp) {
    line-height: 2;
  }
}

.about-philosophy_mvv_valueList {
  counter-reset: count 0;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.about-philosophy_mvv_valueList_item {
  text-indent: -1.6rem;
  padding-left: 1.6rem;
  @include media(pc) {
  
  }
  
  @include media(sp) {
    margin-bottom: sp(10);
  }

  &::before {
    content: counter(count) ". ";
    counter-increment: count 1;
  }
}

.about-philosophy_mvv_valueList_item_en {
  
  @include media(pc) {
    font-size: 16px;
  }
  
  @include media(sp) {
  
  }
}


.about-topmessage {
  position: relative;
  @include media(pc) {
    margin-top: 100px;
  }
  
  @include media(sp) {
    margin-top: sp(50);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    background: $color-primary;
    top: 0;
    left: 0;
    width: 0;
    transition: width 1s ease;
    @include media(pc) {
      height: 450px;
    }
    
    @include media(sp) {
      height: sp(200);
    }
  }

  &.aos-animate {
    &::before {
      width: 100%;
    }
  }
}

.about-topmessage_container {
  position: relative;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.about-topmessage_heading {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  letter-spacing: .2rem;
  color: #fff;
  
  @include media(pc) {
    font-size: 30px;
    padding: 100px 0 50px;
  }
  
  @include media(sp) {
    padding: sp(30) 0 sp(20);
  }
}

.about-topmessage_image {
  
  @include media(pc) {
    margin-bottom: 80px;
  }
  
  @include media(sp) {
    margin-bottom: sp(40);
  }
}

.about-topmessage_title {
  font-weight: 700;
  
  @include media(pc) {
    font-size: 24px;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  @include media(sp) {
    line-height: 2;
    font-size: sp(20);
    margin-bottom: sp(30);
  }
}

.about-topmessage_body {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.about-topmessage_p {
  line-height: 2.2;
  margin-bottom: 1.5rem;
  @include media(pc) {
  }
  
  @include media(sp) {
  
  }
}

.about-topmessage_sign {
  text-align: right;
  margin-top: 3rem;
  @include media(pc) {
    font-size: 18px;
  }
  
  @include media(sp) {
  
  }
}

.about-outline {
  @include media(pc) {
    margin-top: 150px;
    margin-bottom: 150px;
  }
  
  @include media(sp) {
    margin-top: sp(100);
    margin-bottom: sp(100);
  }
}

.about-outline_heading {
  font-family: 'Josefin Sans', sans-serif;;
  font-weight: 700;
  letter-spacing: .2rem;
  @include media(pc) {
    font-size: 30px;
    margin-bottom: 40px;
  }
  
  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(30);
  
  }
}

.about-outline_table {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
    display: block;
    margin-bottom: sp(40);
  }
}

.about-outline_table_tbody {
  line-height: 2;
  @include media(pc) {
  
  }
  
  @include media(sp) {
    display: block;
  
  }
}

.about-outline_table_row {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
    display: block;
  
  }
}

.about-outline_table_head {
  vertical-align: top;
  text-align: left;
  font-weight: 700;
  @include media(pc) {
    width: 18.5%;
    padding: 15px 15px 15px 0;
  }
  
  @include media(sp) {
    display: block;
    margin-bottom: sp(5);
    font-size: sp(18);
    padding-top: sp(20);
  }
}

.about-outline_table_data {
  
  @include media(pc) {
    width: 81.5%;
    padding: 15px 0 15px 15px;
  }
  
  @include media(sp) {
    display: block;
    margin-bottom: sp(20);
    font-size: sp(15);
  }
}

.about-outline_history {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.about-outline_history_container {
  
  @include media(pc) {
    display: flex;
  }
  
  @include media(sp) {
  
  }

  & + & {
    @include media(pc) {
      margin-top: 30px;
    }
    
    @include media(sp) {
    
    }
  }
}

.about-outline_history_year {
  
  @include media(pc) {
    width: 10rem;
  }
  
  @include media(sp) {
    font-weight: 700;
  }
}

.about-outline_history_data {
  
  @include media(pc) {
    flex: 1;
  }
  
  @include media(sp) {
    margin-bottom: sp(10);
  }
}


.access-base {
  
  @include media(pc) {
    margin-bottom: 150px;
  }
  
  @include media(sp) {
    margin-bottom: sp(100);
  }
}

.access-base_map {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  iframe {
    width: 100%;
    @include media(pc) {
      height: 500px;
    }
    
    @include media(sp) {
    
    }
  }
}

.access-base_column {
  
  @include media(pc) {
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
  }
  
  @include media(sp) {
  
  }
}

.access-base_column_main {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.access-base_name {
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  @include media(pc) {
    font-size: 20px;
  
  }
  
  @include media(sp) {
  
  }
}

.access-base_p {
  line-height: 2;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.access-base_column_sub {
  
  @include media(pc) {
    margin-left: 50px;
  }
  
  @include media(sp) {
  
  }
}

.access-base_btn {
  text-decoration: none;
  color: #000;
  position: relative;
  transition: color 0.4s;
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  align-items: center;
  border: 1px solid #000;
  text-align: center;
  justify-content: center;
  width: 100%;
  line-height: 1.5;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  
  @include media(pc) {
    padding: 1.8rem 2.2rem;
  
  }
  
  @include media(sp) {
  
  }
}

.access-base_btn_text {
  position: relative;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.privacy-main {
  @include media(pc) {
    margin-bottom: 150px;
  }

  @include media(sp) {
    margin-bottom: sp(100);
  }
}

.privacy-heading {
  font-weight: 700;
  margin-top: 4rem;
  margin-bottom: 1rem;
  @include media(pc) {
    font-size: 18px;
  }
  
  @include media(sp) {
  
  }
}

.privacy-p {
  line-height: 2.2;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.recruit-slide {
  position: relative;
  @include media(pc) {
    margin-top: 60px;
    margin-bottom: 160px;
  }
  
  @include media(sp) {
    margin-top: sp(60);
    margin-bottom: sp(100);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 0;
    height: 200px;
    background-color: $color-primary;
    transition: width 1s ease;
    @include media(pc) {
    
    }
    
    @include media(sp) {
    
    }
  }

  &.aos-animate {
    &::before {
      width: 50%;
    }
  }

  .splide__arrows, .splide__pagination { display: none; }
}


.recruit-nav {
  display: flex;
  flex-wrap: wrap;
  @include media(pc) {
    
  }
  
  @include media(sp) {

  }
}

.recruit-nav_item {
  
  @include media(pc) {
    margin-bottom: 30px;
    margin-right: 30px;
  }
  
  @include media(sp) {
    margin-bottom: sp(20);
    margin-right: sp(20);
  }
  & + & {
    @include media(pc) {

    }
    
    @include media(sp) {
    
    }
  }
}

.recruit-nav_anchor {
  text-decoration: none;
  color: #fff;
  transform: skew(-15deg);
  display: inline-block;
  position: relative;
  line-height: 1.4;
  background-color: #121212;
  position: relative;
  transition: color 0.4s;
  font-weight: 700;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  
  @include media(pc) {
    padding: 1rem 1.5rem;
  }
  
  @include media(sp) {
    padding: .8rem 1.5rem;
  
  }
}

.recruit-nav_text {
  display: inline-block;
  transform: skew(15deg);
  position: relative;
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}


.recruit-section {
  
  @include media(pc) {
    margin-top: 100px;
  }
  
  @include media(sp) {
    margin-top: sp(70);
  }
}

.recruit-type {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.recruit-heading {
  font-weight: 700;
  letter-spacing: .2rem;
  @include media(pc) {
    font-size: 32px;
    margin-bottom: 50px;
  }
  
  @include media(sp) {
    font-size: sp(24);
    margin-bottom: sp(30);
  }
}

.recruit-type_heading {
  font-weight: 700;
  display: flex;
  @include media(pc) {
    font-size: 20px;
    margin-bottom: 2rem;
    margin-top: 50px;
  }
  
  @include media(sp) {
    margin-bottom: 1.2rem;
    margin-top: sp(50);
    font-size: sp(18);
  }

  &::before {
    content: '';
    display: block;
    background-color: $color-primary;
    margin-right: .8rem;
    @include media(pc) {
      width: 16px;
      height: 3px;
      margin-top: calc(10px - 1.5px);
    }
    
    @include media(sp) {
      width: sp(16);
      height: sp(3);
      margin-top: sp(7);
    }
  }
}

.recruit-type_table {
  
  @include media(pc) {
  }
  
  @include media(sp) {
    display: block;
  }
}

.recruit-type_row {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
    display: block;
  }

  & + & {
    @include media(pc) {

    }

    @include media(sp) {
      margin-top: sp(4);
    }
  }
}

.recruit-type_head {
  font-weight: 700;
  background-color: #f5f5f5;
  vertical-align: top;
  text-align: left;
  line-height: 2;
  @include media(pc) {
    font-weight: 700;
    padding: 40px 30px;
    border-bottom: 3px solid #fff;
    width: 18%;
  }
  
  @include media(sp) {
    display: block;
    padding: sp(20) sp(20) sp(10) sp(20);
    font-size: sp(17);
  
  }
}

.recruit-type_data {
  background-color: #f5f5f5;
  line-height: 2;
  
  @include media(pc) {
    padding: 40px 30px;
    border-bottom: 3px solid #fff;
    width: 82%;
  
  }
  
  @include media(sp) {
    display: block;
    padding: 0 sp(20) sp(20) sp(20);
    font-size: sp(14);
  
  }
}

.recruit-info {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.recruit-table {
  
  border-bottom: 1px solid #e6e6e6;
  @include media(pc) {
  
  }
  
  @include media(sp) {
    display: block;
  }
}

.recruit-table_tbody {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
    display: block;
  
  }
}

.recruit-table_row {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
    display: block;
  
  }
}

.recruit-table_head {
  font-weight: 700;
  background-color: #f5f5f5;
  line-height: 1.6;
  vertical-align: top;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  @include media(pc) {
    width: 18%;
    padding: 20px 30px;
  
  }
  
  @include media(sp) {
    display: block;
    padding: sp(10) sp(20);
  }
}

.recruit-table_data {
  line-height: 1.6;
  border-top: 1px solid #e6e6e6;
  @include media(pc) {
    width: 82%;
    padding: 20px 30px;
  }
  
  @include media(sp) {
    display: block;
    font-size: sp(14);
    padding: sp(15) sp(20) sp(20);
  
  }
}

.recruit-about {
  
  @include media(pc) {
    padding-bottom: 150px;
  }
  
  @include media(sp) {
    padding-bottom: sp(100);
  }
}

.service-message {
  @include media(pc) {
    padding-bottom: 100px;
  }

  @include media(sp) {
    padding-bottom: sp(50);
  }
}

.service-message_lead {
  line-height: 2;
  font-weight: 700;
  @include media(pc) {
    font-size: 28px;
    margin-bottom: 60px;
  }

  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(30);
  }
}

.service-list {
  background: #f5f5f5;
  @include media(pc) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(50);
  }

  & + & {
    padding-top: 0;
    @include media(pc) {
    }

    @include media(sp) {

    }
  }
}

.service-list_container {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.service-list_column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include media(pc) {
  }

  @include media(sp) {

  }
}

.service-list_column_item {
  @include media(pc) {
    width: 47.22%;
    margin-bottom: 40px;
  }

  @include media(sp) {
    margin-bottom: sp(30);
  }

  &:nth-child(even) {
    @include media(pc) {

    }

    @include media(sp) {

    }
  }
}

.service-list_column_image {
  overflow: hidden;
  @include media(pc) {
    border-radius: 8px;
  }

  @include media(sp) {
    border-radius: sp(6);
  }
}

.service-list_column_category {
  @include media(pc) {
    margin-top: 20px;
  }

  @include media(sp) {
    margin-top: sp(10);
  }
}

.service-list_column_category_item {
  background-color: #fff;
  display: inline-block;
  @include media(pc) {
    font-size: 15px;
    margin-bottom: 8px;
    padding: .5rem 1rem;
  }

  @include media(sp) {
    font-size: sp(13);
    margin-bottom: sp(6);
    padding: .5rem .8rem;
  }
}

.service-works {
  @include media(pc) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(50);

  }
}

.service-list_container {
  @include media(pc) {

  }

  @include media(sp) {

  }
}



.service-works_slide {
  position: relative;
  @include media(pc) {
    margin-top: 160px;
    margin-bottom: 60px;
  }

  @include media(sp) {
    margin-top: sp(60);
    margin-bottom: sp(100);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 0;
    height: 200px;
    background-color: $color-primary;
    transition: width 1s ease;
    @include media(pc) {

    }

    @include media(sp) {

    }
  }

  &.aos-animate {
    &::before {
      width: 50%;
    }
  }

  .splide__arrows, .splide__pagination { display: none; }
}


.service-process {
  background-color: #F5F5F5;
  @include media(pc) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(50);

  }
}

.service-lead {
  line-height: 2;
  @include media(pc) {
    font-size: 17px;
  }

  @include media(sp) {

  }
}

.service-list_process_phase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .service-list_process_phase {
    margin-left: -7.8125vw;
    margin-right: -7.8125vw;
    margin-top: 10.41667vw;
    padding-bottom: 7.8125vw;
  }
}

.service-list_process_phase_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  filter: brightness(0%);
}

.service-list_process_phase_item.aos-animate {
  filter: brightness(100%);
}

@media (max-width: 768px) {
  .service-list_process_phase_item {
    margin: 0 2.60417vw;
    width: 66.40625vw;
  }
}

@media (min-width: 769px) {
  .service-list_process_phase_item.a-col-3 {
    width: 32%;
  }
}

@media (min-width: 769px) {
  .service-list_process_phase_item.a-col-3 + .a-col-3 {
    margin-left: 2%;
  }
}

@media (min-width: 769px) {
  .service-list_process_phase_item.a-col-4 {
    width: 24%;
  }
}

@media (min-width: 769px) {
  .service-list_process_phase_item.a-col-4 + .a-col-4 {
    margin-left: 1.3%;
  }
}

.service-list_process_phase_item + .service-list_process_phase_item {
  position: relative;
}

@media (min-width: 769px) {
  .service-list_process_phase_item + .service-list_process_phase_item .service-list_process_phase_desc {
    border-left: 1px solid #E6E6E6;
  }
}

@media (min-width: 769px) {
  .service-list_process_phase_item + .service-list_process_phase_item::before {
    position: absolute;
    left: 0;
    top: 22%;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 13px;
    border-color: transparent transparent transparent #000000;
  }
}

@media (max-width: 960px) {
  .service-list_process_phase_item + .service-list_process_phase_item::before {
    top: 18%;
    border-width: 16px 0 16px 10px;
  }
}

.service-list_process_phase_image {
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 769px) {
  .service-list_process_phase_image {
    width: 240px;
    height: 240px;
  }
}

@media (max-width: 768px) {
  .service-list_process_phase_image {
    width: 52.08333vw;
    height: 52.08333vw;
  }
}

@media (max-width: 1000px) {
  .a-col-3 .service-list_process_phase_image {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 768px) {
  .a-col-3 .service-list_process_phase_image {
    width: 52.08333vw;
    height: 52.08333vw;
  }
}

@media (max-width: 1120px) {
  .a-col-4 .service-list_process_phase_image {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 960px) {
  .a-col-4 .service-list_process_phase_image {
    width: 160px;
    height: 160px;
  }
}

@media (max-width: 768px) {
  .a-col-4 .service-list_process_phase_image {
    width: 52.08333vw;
    height: 52.08333vw;
  }
}

@media (min-width: 769px) {
  .service-list_process_phase_image_icon {
    width: 100px;
  }
}

@media (max-width: 960px) {
  .service-list_process_phase_image_icon {
    width: 80px;
  }
}

@media (max-width: 768px) {
  .service-list_process_phase_image_icon {
    width: 20.83333vw;
  }
}

.service-list_process_phase_image_icon img {
  width: 100%;
}

.service-list_process_phase_image_step {
  color: #C8161D;
}

@media (min-width: 769px) {
  .service-list_process_phase_image_step {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .service-list_process_phase_image_step {
    font-size: 3.125vw;
  }
}

.service-list_process_phase_image_title {
  font-weight: 700;
}

@media (min-width: 769px) {
  .service-list_process_phase_image_title {
    margin-top: 5px;
  }
}

@media (max-width: 960px) {
  .service-list_process_phase_image_title {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .service-list_process_phase_image_title {
    font-size: 3.64583vw;
    margin-top: 2.08333vw;
  }
}

.service-list_process_phase_task {
  margin-top: 30px;
}

.service-list_process_phase_task_item {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 700;
  -webkit-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  transform: skew(-30deg);
}

@media (min-width: 769px) {
  .service-list_process_phase_task_item {
    height: 35px;
  }
}

@media (max-width: 960px) {
  .service-list_process_phase_task_item {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .service-list_process_phase_task_item {
    font-size: 3.38542vw;
    height: 9.11458vw;
  }
}

@media (min-width: 769px) {
  .service-list_process_phase_task_item:nth-child(2) {
    -webkit-transform: translateX(-20px) skew(-30deg);
    -ms-transform: translateX(-20px) skew(-30deg);
    transform: translateX(-20px) skew(-30deg);
  }
}

@media (max-width: 768px) {
  .service-list_process_phase_task_item:nth-child(2) {
    -webkit-transform: translateX(-5.20833vw) skew(-30deg);
    -ms-transform: translateX(-5.20833vw) skew(-30deg);
    transform: translateX(-5.20833vw) skew(-30deg);
  }
}

.service-list_process_phase_task_item.a-black {
  background-color: #000000;
  color: #fff;
}

.service-list_process_phase_task_item.a-red {
  background-color: #C8161D;
  color: #fff;
}

.service-list_process_phase_task_item.a-full {
  width: 100%;
}

.service-list_process_phase_task_item.a-half {
  width: 50%;
  margin-left: auto;
}

.service-list_process_phase_task_item.a-tq {
  width: 70%;
  margin-left: auto;
}

@media (max-width: 768px) {
  .service-list_process_phase_task_item.a-tq {
    width: 50%;
  }
}

.service-list_process_phase_task_item > span {
  -webkit-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}

.service-list_process_phase_desc {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (min-width: 769px) {
  .service-list_process_phase_desc {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .service-list_process_phase_desc {
    margin-top: 5.20833vw;
    font-size: 3.64583vw;
    margin-left: -3.90625vw;
  }
}

.service-list_process_phase_desc_item {
  text-indent: -1rem;
  padding-left: 1rem;
  line-height: 1.6;
}

.service-list_process_phase_desc_item::before {
  content: '・';
  display: inline;
}

.service-list_process_phase_desc_item + .service-list_process_phase_desc_item {
  margin-top: .5rem;
}

.service-list_process_phase_arrow {
  position: absolute;
  top: 36%;
  cursor: pointer;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  -o-transition: transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  z-index: 2;
}

@media (min-width: 769px) {
  .service-list_process_phase_arrow {
    display: none;
  }
}

@media (max-width: 768px) {
  .service-list_process_phase_arrow {
    width: 8.33333vw;
  }
}

.service-list_process_phase_arrow img {
  width: 100%;
}

.service-list_process_phase_arrow:active {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.service-list_process_phase_arrow.slick-disabled {
  display: none !important;
}

.service-list_process_phase_arrow-prev {
  left: 7.8125vw;
}

.service-list_process_phase_arrow-next {
  right: 7.8125vw;
}

.service-list_process_phase .slick-dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: -2.60417vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.service-list_process_phase .slick-dots li.slick-active button {
  background-color: #A5A5A5;
}

.service-list_process_phase .slick-dots li + li {
  margin-left: 2.60417vw;
}

.service-list_process_phase .slick-dots li button {
  border-radius: 50%;
  text-indent: -9999px;
  border: 0;
  width: 8px;
  height: 8px;
  padding: 0;
  background-color: #DDDDDD;
}

.service-strongPoint {
  @include media(pc) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(50);

  }
}

.service-strongPoint_table {
  width: 100%;
  border-bottom: 1px solid #ddd;
  @include media(pc) {

  }

  @include media(sp) {
    display: block;
    margin-top: sp(30);
  }
}
.service-strongPoint_table_tbody {
  @include media(pc) {

  }

  @include media(sp) {
    display: block;
  }
}
.service-strongPoint_table_row {
  @include media(pc) {

  }

  @include media(sp) {
    border-top: 1px solid #ddd;
    display: block;
    padding: sp(20) 0;
  }
}
.service-strongPoint_table_head {
  line-height: 1.8;
  text-align: left;
  @include media(pc) {
    border-top: 1px solid #ddd;
    padding: 30px 30px 30px 0;
    width: 30%;
  }

  @include media(sp) {
    display: flex;
    margin-bottom: sp(10);
  }
}
.service-strongPoint_table_head_number {
  color: #C8161D;
  display: inline-block;
  font-family: 'Josefin Sans',sans-serif;
  @include media(pc) {
    width: 2.4rem;
    font-size: 30px;
    line-height: 1.3;
    vertical-align: top;
  }

  @include media(1000px) {
    display: block;
  }

  @include media(sp) {
    font-size: sp(26);
    margin-top: -.4rem;
  }
}
.service-strongPoint_table_head_title {
  display: inline-block;
  @include media(pc) {
    font-size: 18px;
  }

  @include media(sp) {
    font-size: sp(17);
    font-weight: 700;
    margin-left: sp(10);
  }
}
.service-strongPoint_table_data {
  vertical-align: middle;
  line-height: 1.8;
  @include media(pc) {
    border-top: 1px solid #ddd;
    font-size: 18px;
    padding: 30px 30px 30px 0;
  }

  @include media(sp) {
    display: block;
    font-size: sp(15);
  }
}

.contact-info_heading {
  font-weight: 700;
  @include media(pc) {
    font-size: 22px;
    margin-top: 70px;
  }

  @include media(sp) {
    font-size: sp(18);
    margin-top: sp(50);
  }
}

.contact-info_block {
}

@media (max-width: 768px) {
  .contact-info_block .column {
  }
}

.contact-info_block .column:first-child {
  margin-right: 5.85652vw;
}

.contact-info_block .column .tel {
  font-size: 50px;
  margin-top: 3.66032vw;
}

@media (max-width: 768px) {
  .contact-info_block .column .tel {
    font-size: 10.41667vw;
    margin-top: 5.85938vw;
  }
}

.contact-info_block .column .mail {
  margin-top: 2.56223vw;
  font-size: 21px;
  width: 32.9429vw;
  padding: 1.83016vw 0;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: solid 1px #000;
}

@media (max-width: 768px) {
  .contact-info_block .column .mail {
    font-size: 4.16667vw;
    width: 100%;
    padding: 4.55729vw 0;
    margin-top: 5.85938vw;
  }
}

.contact-info_block .contact-info_block_title {
  font-size: 18px;
  padding-left: 0;
}

.contact-info_block .contact-info_block_title::after {
  display: none;
}

@media (max-width: 768px) {
  .contact-info_block .contact-info_block_title {
    font-size: 4.6875vw;
  }
}

.contact-info_block_body {
  border-radius: 5px;
  -webkit-box-shadow: 0 0 8px #ddd;
  box-shadow: 0 0 8px #ddd;
  width: 100%;
  padding: 30px 60px;
  margin-top: 2rem;
  line-height: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {
  .contact-info_block_body {
    padding: 7.8125vw 3.90625vw 3.90625vw;
    display: block;
    text-align: center;
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .contact-info_block_info {
    font-size: 3.90625vw;
  }
}

.contact-info_block_tel {
  border-left: 1px solid #e6e6e6;
  padding-left: 50px;
  margin-left: 50px;
  font-size: 40px;
}

@media (max-width: 768px) {
  .contact-info_block_tel {
    border-left: 0;
    border-top: 1px solid #e6e6e6;
    padding-left: 0;
    margin-left: 0;
    padding-top: 1rem;
    margin-top: 1rem;
    font-size: 9.11458vw;
  }
}

.contact-info_block_tel a {
  color: #000;
}

.contact-info_block_tel_number {
  text-decoration: none;
  @include media(pc) {
    pointer-events: none;
  }

  @include media(sp) {

  }
}


.contact-main {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.contact-main_head {
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: #d7000f;

  @include media(pc) {
    padding: 100px 0px 240px;
    margin-top: 100px;
  }

  @include media(sp) {
    padding: sp(50) 0 sp(120);
    margin-top: sp(50);
  }
}

.contact-main_heading {
  font-weight: 700;
  @include media(pc) {
    font-size: 34px;
    margin-bottom: 30px;
  }

  @include media(sp) {
    font-size: sp(26);
    margin-bottom: sp(20);
  }
}

.contact-main_lead {
  line-height: 1.8;
  @include media(pc) {
  }

  @include media(sp) {
    font-size: sp(15);
  }
}

.contact-main_body {
  @include media(pc) {
    max-width: 1370px;
    padding-left: 32px;
    padding-right: 32px;
    margin: 0 auto;
  }

  @include media(sp) {
    padding-left: sp(20);
    padding-right: sp(20);
  }
}

.contact-main_form {
  background-color: #fff;
  @include media(pc) {
    padding: 100px;
    margin-top: -170px;
  }

  @include media(sp) {
    padding: sp(30);
    margin-top: sp(-80);
  }
}

.contactForm_head--flow { width: 100%; background-color: #d7000f; padding: 100px 0px; margin-top: 100px; text-align: center; color: rgb(255, 255, 255); }
@media (max-width: 768px) {
  .contactForm_head--flow { padding: 10.4167vw 0px; margin-top: 13.0208vw; }
}
.contactForm_head_title { margin: 0px 0px 2rem; }
@media (max-width: 768px) {
  .contactForm_head_title { font-size: 4.6875vw; margin: 0px 0px 1rem; }
}
.contactForm_container { max-width: 1370px; padding: 0px 20px; margin: 0px auto; }
@media (max-width: 768px) {
  .contactForm_container { padding: 0px 7.8125vw; }
}
.contactForm_body { background-color: rgb(255, 255, 255); padding: 100px 140px; margin-top: -180px; }
@media (max-width: 768px) {
  .contactForm_body { margin-top: 4rem; padding: 0px; }
}
.contactForm_message { line-height: 1.8; text-align: center; }
.contactForm_table { width: 100%; }
@media (max-width: 768px) {
  .contactForm_table { display: block; }
}
@media (max-width: 768px) {
  .contactForm_table tbody { display: block; }
}
@media (max-width: 768px) {
  .contactForm_table tr { display: block; }
}
.contactForm_table th { border-top: 1px solid rgb(0, 0, 0); width: 28%; text-align: left; padding: 30px; vertical-align: top; line-height: 1.6; }
@media (max-width: 768px) {
  .contactForm_table th { display: block; width: 100%; padding: 1rem 0px; font-size: 3.90625vw; }
}
.contactForm_table td { padding-left: 50px; padding-bottom: 30px; width: 72%; vertical-align: top; line-height: 1.6; }
@media (max-width: 768px) {
  .contactForm_table td { display: block; width: 100%; padding-left: 0px; font-size: 4.16667vw; }
}
.contactForm_required { color: #d7000f; }
.contactForm_input, .contactForm_textarea { background-color: rgb(245, 245, 245); border-radius: 3px; border: 0px; padding: 20px; width: 100%; font-size: 16px; line-height: 1.8; font-family: "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }
@media (max-width: 768px) {
  .contactForm_input, .contactForm_textarea { font-size: 4.16667vw; padding: 2.60417vw; }
}
.contactForm_textarea { height: 200px !important; width: 100% !important; }
@media (max-width: 768px) {
  .contactForm_textarea { height: 39.0625vw !important; }
}
.contactForm_submitArea_wrap { max-width: 400px; margin: 4rem auto 0px; }
@media (max-width: 768px) {
  .contactForm_submitArea_wrap { margin: 1rem auto 0px; }
}
.contactForm_submitArea { font-family: "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; text-align: center; border: 1px solid rgb(0, 0, 0); width: 100%; transition: all 0.3s ease 0s; padding: 0px; }
.contactForm_submitArea:hover { background-color: rgb(0, 0, 0); color: rgb(255, 255, 255); }
.contactForm_submitArea:hover .contactForm_submit { background-color: rgb(0, 0, 0); color: rgb(255, 255, 255); }
.contactForm_submit { transition: all 0.3s ease 0s; border: 0px; width: 100%; font-size: 16px; cursor: pointer; padding: 2rem; z-index: 1; background-color: rgb(255, 255, 255); }
@media (max-width: 768px) {
  .contactForm_submit { font-size: 4.16667vw; padding: 1.5rem 2rem; }
}
#mfp_hidden { display: none; }
#mfp_warning { color: #d7000f; margin-bottom: 1rem; line-height: 1.6; padding: 0.5rem 1rem; }
.moduleButton { display: block; border: 1px solid rgb(0, 0, 0); padding: 2rem; color: rgb(0, 0, 0); text-align: center; max-width: 400px; margin: 4rem auto; transition: all 0.2s ease 0s; }
@media (max-width: 768px) {
  .moduleButton { padding: 0.5rem; margin-top: 1rem; }
}
.moduleButton:hover { background-color: rgb(0, 0, 0); color: rgb(255, 255, 255); }
.contactForm_tableConfirm { width: 100%; }
@media (max-width: 768px) {
  .contactForm_tableConfirm { display: block; }
}
@media (max-width: 768px) {
  .contactForm_tableConfirm tbody { display: block; }
}
@media (max-width: 768px) {
  .contactForm_tableConfirm tr { display: block; }
}
.contactForm_tableConfirm th { border-top: 1px solid rgb(0, 0, 0); width: 28%; text-align: left; padding: 20px 30px 30px; vertical-align: top; line-height: 1.6; }
@media (max-width: 768px) {
  .contactForm_tableConfirm th { display: block; width: 100%; padding: 1rem 0px; font-size: 3.90625vw; }
}
.contactForm_tableConfirm td { padding: 20px 30px 30px; width: 72%; vertical-align: top; line-height: 1.6; }
@media (max-width: 768px) {
  .contactForm_tableConfirm td { display: block; width: 100%; padding: 1rem 0px; font-size: 4.16667vw; }
}
#mfp_OperationCheck { display: none; }
.mfp_element_button { width: 100%; font-size: 16px; cursor: pointer; padding: 2rem; z-index: 1; background-color: rgb(255, 255, 255); border: 1px solid rgb(0, 0, 0); transition: all 0.3s ease 0s; }
@media (max-width: 768px) {
  .mfp_element_button { font-size: 4.16667vw; padding: 1.5rem 2rem; }
}
.mfp_element_button:hover { background-color: rgb(0, 0, 0); color: rgb(255, 255, 255); }
.mfp_element_button + .mfp_element_button { margin-left: 2%; }
.mfp_buttons { display: flex; margin-top: 4rem; }
#mfp_overlay_background { display: none; }
#mfp_loading_screen { display: none; }


.works-category {
  display: flex;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  @include media(pc) {
    margin-bottom: 64px;
  }

  @include media(sp) {
    margin-bottom: sp(40);
  }
}

.works-category_item {
  background: transparent;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  border: 0;
  cursor: pointer;
  position: relative;
  padding: 0;


  & + & {
    @include media(pc) {
      margin-left: 32px;
    }

    @include media(sp) {
      margin-left: sp(24);
    }
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -.4rem;
    width: 0;
    height: 5px;
    background-color: #C8161D;
    transition: width .2s ease;
  }

  &.is-current {
    &::after {
      position: absolute;
      left: 0;
      bottom: -.4rem;
      width: 100%;
      height: 5px;
      background-color: #C8161D;
    }
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

  @include media(pc) {

  }

  @include media(sp) {
    font-size: 1rem;
  }
}


.works-category_item_text {
  position: relative;
  z-index: 1;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.works-content {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8rem;
  transition: opacity .3s ease;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.works-content_wrap {
  display: flex;
  flex-wrap: wrap;
  @include media(pc) {
    margin-left: -5%;
  }

  @include media(sp) {

  }
}

.works-content_item {
  margin-bottom: 2rem;
  cursor: pointer;
  transition: opacity .2s ease;
  @include media(pc) {
    width: 28.3%;
    margin-left: 5%;
  }

  @include media(sp) {
    width: 100%;
  }

  &:hover {
    opacity: 0.7;
  }

  img {
    width: 100%;
    height: auto;
  }

}

.works-content_item_thumb {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.works-content_item_thumb_image {
  margin-bottom: .6rem;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.works-content_item_thumb_client {
  line-height: 1.6;
  margin-bottom: 0.4rem;
  display: block;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.works-content_item_thumb_tag {
  line-height: 1.6;
  font-size: 0.8rem;
  display: block;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.works-modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, .7);
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease;
  @include media(pc) {

  }

  @include media(sp) {

  }
  &.is-show {
    pointer-events: auto;
    opacity: 1;
    display: flex;
  }
}
.works-modal_close {
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  font-weight: 900;
  font-size: 2rem;
  background: transparent;
  border: 0;
  color: #fff;
  @include media(pc) {
    top: 2rem;
    right: 2rem;
  }

  @include media(sp) {
    top: 1rem;
    right: 1rem;

  }
}
.works-modal_content {
  @include media(pc) {
    max-width: 1200px;
    padding: 32px;
    margin: 0 auto;
  }

  @include media(sp) {
    padding: 1rem;
  }
}

.works-modal_content_image {
  overscroll-behavior: contain;
  margin-bottom: 1rem;
  overflow: auto;
  @include media(pc) {
    max-height: 600px;
  }

  @include media(sp) {
    max-height: 500px;
  }
}
.works-modal_content_youtube {
  width: 1136px;
  @include media(1200px, max) {
    width: 90vw;
  }
}
.works-modal_content_youtube_container {
  width: 100%;
  aspect-ratio: 16 / 9;
  iframe {
    width: 100%;
    height: 100%;
  }
}
.works-modal_content_image_item {
  @include media(pc) {

  }

  @include media(sp) {

  }
}
.works-modal_content_tag {
  color: #fff;
  font-weight: 700;
  line-height: 1.6;
  @include media(pc) {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  @include media(sp) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
.works-modal_content_desc {
  color: #fff;
  line-height: 1.6;
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {

  }
}
.works-modal_content_wrap {
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {

  }

  & + & {
    @include media(pc) {
      margin-left: 2rem;
    }

    @include media(sp) {
      margin-top: 1rem;
    }
  }
}
.works-modal_content_desc_term {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  margin-right: 1rem;
  @include media(pc) {

  }

  @include media(sp) {
    font-size: .9rem;
  }
}
.works-modal_content_desc_val {
  line-height: 1.5;
  @include media(pc) {

  }

  @include media(sp) {

  }
}


.works-main {
  @include media(pc) {
    padding-bottom: 100px;
  }

  @include media(sp) {
    padding-bottom: sp(100);
  }
}

.works-main_container {

  @include media(pc) {
    max-width: 1500px + 32px * 2;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media(sp) {
    padding-left: sp(30);
    padding-right: sp(30);
  }
}

.works-main_list {
  display: flex;
  flex-wrap: wrap;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.works-main_list_item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @include media(pc) {
    width: 33.33%;
    height: 300px;
    padding: 32px;
  }
  @include media(1367px, min) {
    &:nth-child(3n+2) { margin-left: -1px; }
    &:nth-child(3n+3) { margin-left: -1px; }
    &:nth-child(n+4) { margin-top: -1px; }
  }
  @include media(1366px) {
    width: 50%;

    &:nth-child(even) { margin-left: -1px; }
    &:nth-child(n+3) { margin-top: -1px; }
  }

  @include media(sp) {
    width: 100%;
    padding: sp(32);
    min-height: sp(200);
    &:nth-child(n+1) { margin-top: -1px; }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    border-color: #DCDCDC;
    border-style: solid;
    border-width: 0;
    transition: border-color .3s ease;
    @include media(pc) {
      width: 80px;
      height: 80px;
    }

    @include media(sp) {
      width: sp(80);
      height: sp(80);
    }
  }

  &.is-hover {
    &::before, &::after {
      border-color: $color-primary;
      z-index: 1;
    }

    .works-main_list_item_border {
      &::before, &::after {
        border-color: $color-primary;
        z-index: 1;
      }
    }
  }

  &::before {
    top: 0;
    left: 0;
    border-top-width: 1px;
    border-left-width: 1px;
  }

  &::after {
    top: 0;
    right: 0;
    border-top-width: 1px;
    border-right-width: 1px;
  }
}

.works-main_list_item_border {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @include media(pc) {

  }

  @include media(sp) {

  }

  &::before, &::after {
    content: '';
    position: absolute;
    border-color: #DCDCDC;
    border-style: solid;
    border-width: 0;
    transition: border-color .3s ease;
    @include media(pc) {
      width: 80px;
      height: 80px;
    }
    @include media(sp) {
      width: sp(80);
      height: sp(80);
    }
  }

  &::before {
    bottom: 0;
    left: 0;
    border-bottom-width: 1px;
    border-left-width: 1px;
  }

  &::after {
    bottom: 0;
    right: 0;
    border-bottom-width: 1px;
    border-right-width: 1px;
  }
}

.works-main_list_item_anchor {
  text-decoration: none;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.works-main_list_item_name {
  line-height: 1.5;
  @include media(pc) {
    font-size: 30px;
  }

  @include media(sp) {
    font-size: sp(20);
  }
}

.works-main_list_item_en {
  font-family: 'Josefin Sans', sans-serif;
  color: #AEAEAE;
  font-weight: 300;
  line-height: 1.5;
  margin-top: .5rem;
  @include media(pc) {

  }

  @include media(sp) {
    font-size: sp(12);
  }
}

.works-main_list_item_category {
   text-align: center;
  @include media(pc) {
    margin-top: 45px;
  }

  @include media(sp) {
    margin-top: sp(20);
  }
}

.works-main_list_item_category_item {
  display: inline-block;
  border: 1px solid #dcdcdc;
  padding: .3rem .5rem;
  border-radius: 4px;
  @include media(pc) {
    font-size: 12px;
  }

  @include media(sp) {
    font-size: sp(12);
  }
}


.works-main_modal {
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-primary;
  @include media(pc) {

  }

  @include media(sp) {

  }

  &.is-open {
    pointer-events: auto;
    opacity: 1;
    z-index: 10000;
    transition: opacity .5s ease .7s;
    @include media(pc) {
    }

    @include media(sp) {
    }

    .works-main_modal_container {
      display: block;
    }
  }
}

.works-main_modal_container {
  display: none;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

