@use "global" as *;

.newyear2024-max {
  width: 100%;
}
.newyear2024 {
  margin-left: auto;
  margin-right: auto;
  background-color: #D7000F;
  position: relative;
  overflow: hidden;
  @include media(pc) {
    max-width: 375px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  @include media(sp) {

  }

}

.newyear2024-header_logo {
  transform: scale(0);
  transition: transform 1s ease;
  @include media(pc) {

  }

  @include media(sp) {

  }
  &.is-inview {
    transform: scale(1);
  }
}

.newyear2024-header {
  @include media(pc) {
    padding-top: 50px;
  }

  @include media(sp) {
    padding-top: sp(50);
  }
}

.newyear2024-monologue {
  text-align: center;
  line-height: 2.4;
  color: #fff;
  font-weight: 700;
  letter-spacing: .1rem;
  padding-left: 0.5rem;
  transition: all 1s ease 1s;
  transform: translateY(50px);
  opacity: 0;

  @include media(pc) {
    margin-bottom: 20px;
  }

  @include media(sp) {

  }

  &.is-inview {
    transform: translateY(0);
    opacity: 1;
  }
}

.newyear2024-monologue_challenge {
  position: relative;
  display: inline-block;
  @include media(pc) {
    font-size: 18px;
  }

  @include media(sp) {
    font-size: sp(18);
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    height: 1px;
    width: 100%;
    bottom: .15rem;
    left: 0;
    background-color: #fff;
  }

  &::after {
    position: absolute;
    display: block;
    content: '';
    height: 1px;
    width: 100%;
    bottom: .35rem;
    left: 0;
    background-color: #fff;
  }
}

.newyear2024-monologue_challenge_first {
  color: #DCAC0D;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.newyear2024-tatsu {
  position: relative;
  @include media(pc) {
    height: 190px;
    margin-top: 20px;
  }

  @include media(sp) {
    height: sp(190);
    margin-top: sp(20);
  }

  &.is-inview {
    .newyear2024-tatsu_ornament {
      opacity: 1;
    }
    .newyear2024-tatsu_character {
      opacity: 1;
    }
  }
}

.newyear2024-tatsu_ornament {
  position: absolute;
  left: 0;
  transition: opacity 1s ease 1s;
  opacity: 0;
  @include media(pc) {
    top: 50px;
  }

  @include media(sp) {
    top: sp(50);
  }

  img {
    @include media(sp) {
      width: sp(120);
    }
  }
}


.newyear2024-tatsu_character {
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity 1s ease 1.5s;
  opacity: 0;
  @include media(pc) {

  }

  @include media(sp) {

  }

  img {
    @include media(sp) {
      width: 100vw;
    }
  }
}

.newyear2024-question {
  background-color: rgba(#E38460, 35%);
  position: relative;

  @include media(pc) {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media(sp) {
    padding-top: sp(80);
    padding-bottom: sp(80);
    margin-top: sp(50);
    margin-bottom: sp(50);
    padding-left: sp(20);
    padding-right: sp(20);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/_/media/page/special/newyear2024/ornament_question_line.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    width: 100%;
    @include media(pc) {
      height: 24px;
    }

    @include media(sp) {
      height: sp(24);
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('/_/media/page/special/newyear2024/ornament_question_line.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    width: 100%;
    @include media(pc) {
      height: 24px;
    }

    @include media(sp) {
      height: sp(24);
    }
  }
}

.newyear2024-question_heading {
  font-weight: 700;
  text-align: center;
  color: #fff;
  @include media(pc) {
    font-size: 30px;
    margin-bottom: 15px;
  }

  @include media(sp) {
    font-size: sp(30);
    margin-bottom: sp(20);
  }
}

.newyear2024-question_image {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.newyear2024-question_text {
  color: #fff;
  margin-bottom: 1rem;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.newyear2024-question_answer {
  text-align: center;
  border-radius: 4px;
  border: 0;
  margin: 0 auto;
  font-family: 'LINESeedJP', 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', sans-serif;
  margin-left: auto;
  margin-right: auto;
  display: block;
  @include media(pc) {
    padding: 0.6rem;
    font-size: 40px;
    width: 204px;
    height: 55px;
  }

  @include media(sp) {
    padding: 0.6rem;
    font-size: sp(40);
    width: sp(204);
    height: sp(55);
  }
  &.is-miss {
    opacity: 0.3;
  }
}

.newyear2024-question_answerArea {
  position: relative;
}

.newyear2024-question_miss {
  position: absolute;
  z-index: 1;
  width: 100%;
  pointer-events: none;
  @include media(pc) {
    top: -10px;
    left: 20px;
  }

  @include media(sp) {
    top: sp(-10);
    left: sp(20);

  }
  &.is-miss {
    pointer-events: auto;
  }
}

.newyear2024-question_miss_zan {
  opacity: 0;
  transform: translateY(-100px);
}

.newyear2024-question_miss_nen {
  opacity: 0;
  transform: translateY(-100px);
}
.newyear2024-question_miss.is-miss {
  .newyear2024-question_miss_zan {
    opacity: 1;
    transform: translateY(0);
    transition: all .5s ease;
  }
}
.newyear2024-question_miss.is-miss {
  .newyear2024-question_miss_nen {
    opacity: 1;
    transform: translateY(0);
    transition: all .5s ease .4s;
  }
}


.newyear2024-question_btn {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  transition: transform .1s ease;
  display: inline-block;
  @include media(pc) {
    margin-top: 35px;
  }

  @include media(sp) {
    margin-top: sp(35);
    width: sp(210);
  }
  &:active {
    transform: scale(.9);
  }

  img {
    width: 100%;
  }
}


.newyear2024-anshint {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.newyear2024-anshint_hint {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.newyear2024-anshint_hint_button {
  @include media(pc) {

  }

  @include media(sp) {

  }

  img {
    @include media(sp) {
      width: sp(220);
    }
  }
}

.newyear2024-anshint_hint_ornament {
  margin-top: -1px;
  @include media(pc) {

  }

  @include media(sp) {

  }

  img {
    @include media(sp) {
      width: sp(120);
    }
  }
}

.newyear2024-anshint_answer {
  text-align: right;
  position: relative;
  @include media(pc) {
    margin-top: -115px;
    margin-bottom: 80px;
  }

  @include media(sp) {
    margin-top: sp(-107);
    margin-bottom: sp(80);

  }
}

.newyear2024-anshint_answer_button {
  @include media(pc) {

  }

  @include media(sp) {

  }

  img {
    margin-right: -2px;
    @include media(sp) {
      width: sp(200);
    }
  }
}

.newyear2024-anshint_answer_ornament {
  @include media(pc) {

  }

  @include media(sp) {

  }

  img {
    margin-left: -2px;
    @include media(sp) {
      width: sp(150);
    }
  }
}


.newyear2024-footer {
  font-weight: 700;
  text-align: center;
  color: #fff;
  @include media(pc) {
    font-size: 14px;
  }

  @include media(sp) {
    font-size: sp(14);
  }
}

.newyear2024-footer_copy {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(pc) {
    height: 40px;
  }

  @include media(sp) {
    height: sp(40);
  }
}

.newyear2024-congratulations {
  position: relative;
  @include media(pc) {
    margin-top: 50px;
  }

  @include media(sp) {
    margin-top: sp(50);
  }
}

.newyear2024-congratulations_content {
  background-image: url('/_/media/page/special/newyear2024/ornament_06.svg');
  background-position: left center;
  background-size: cover;
  position: relative;
  z-index: 1;
  @include media(pc) {
    height: 336px;
    padding-top: 30px;
  }

  @include media(sp) {
    height: sp(336);
    padding-top: sp(30);

  }
}

.newyear2024-congratulations_main {
  position: absolute;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  @include media(pc) {
    top: 30px;
    width: 296px;
  }

  @include media(sp) {
    top: sp(30);
    width: sp(296);
  }

  img {
    @include media(sp) {
      width: 100%;
    }
  }
}

.newyear2024-congratulations_rotate {
  text-align: center;
  opacity: 0.2;

  @include media(pc) {
    transform: translateY(130px) scale(1.4);
    margin-top: -280px;
  }

  @include media(sp) {
    transform: translateY(sp(130)) scale(1.4);
    margin-top: sp(-280);

  }
}

.newyear2024-congratulations_rotate img {
  animation: rotate_anime 5s linear infinite;

  @include media(pc) {

  }

  @include media(sp) {

  }
}
@keyframes rotate_anime {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.newyear2024-epilogue {
  background-color: #fff;
  text-align: center;
  font-weight: 700;
  position: relative;
  z-index: 1;
  @include media(pc) {
    padding-top: 20px;
    padding-bottom: 80px;
    margin-top: -94px;
  }

  @include media(sp) {
    padding-top: sp(20);
    padding-bottom: sp(80);
    margin-top: sp(-94);

  }
}

.newyear2024-epilogue_mizuhiki {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.newyear2024-epilogue_title {
  @include media(pc) {
    font-size: 22px;
    margin-bottom: 30px;
  }

  @include media(sp) {
    font-size: sp(22);
    margin-bottom: sp(30);

  }
}

.newyear2024-epilogue_body {
  line-height: 2.2;
  @include media(pc) {
    font-size: 15px;
  }

  @include media(sp) {
    font-size: sp(15);
  }
}

.newyear2024-epilogue_body_answer {
  @include media(pc) {

  }

  @include media(sp) {

  }
}


.newyear2024-modal {
  background-color: #D7000F;
  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  height: 100%;
  align-items: center;
  display: none;
  z-index: 100;
  @include media(pc) {
    width: 373px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media(sp) {
    padding-left: sp(20);
    padding-right: sp(20);
  }
}

.newyear2024-modal_close {
  color: #fff;
  font-weight: 700;
  text-align: center;
   font-size: 1.1rem;
  @include media(pc) {
    margin-top: 20px;
  }

  @include media(sp) {
    margin-top: sp(20);
  }

  .icon-plus::before {
    transform: rotate(45deg) scale(1.5);
    margin-right: .5rem;
  }
}

.newyear2024-modal_message {
  color: #fff;
  line-height: 1.9;
  margin-top: 1rem;
  @include media(pc) {
    font-size: 14px;
  }

  @include media(sp) {
    font-size: sp(14);
  }
}

.newyear2024-modal_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @include media(pc) {

  }

  @include media(sp) {

  }
}


.newyear2024-confetti {
    position: absolute;
    width: 100%;
    height: 100%;
}

.newyear2024-confetti span {
    position: absolute;
    top: -10%;
    left: 0;
    width: 10px;
    height: 10px;
    background: #fff;
    z-index: 2;
}
@media screen and (max-width:750px) {
    .newyear2024-confetti span {
        width: sp(10);
        height: sp(10);
    }
}

.newyear2024-confetti span:nth-child(2n+1) { animation: confetti-anim-1 .1s 0s linear infinite; }
.newyear2024-confetti span:nth-child(2n+2) { animation: confetti-anim-2 .1s 0s linear infinite; }

.newyear2024-confetti span:nth-child(1) { left: 0%; }
.newyear2024-confetti span:nth-child(2) { left: 4%; }
.newyear2024-confetti span:nth-child(3) { left: 8%; }
.newyear2024-confetti span:nth-child(4) { left: 12%; }
.newyear2024-confetti span:nth-child(5) { left: 16%; }
.newyear2024-confetti span:nth-child(6) { left: 20%; }
.newyear2024-confetti span:nth-child(7) { left: 24%; }
.newyear2024-confetti span:nth-child(8) { left: 28%; }
.newyear2024-confetti span:nth-child(9) { left: 32%; }
.newyear2024-confetti span:nth-child(10) { left: 36%; }
.newyear2024-confetti span:nth-child(11) { left: 40%; }
.newyear2024-confetti span:nth-child(12) { left: 44%; }
.newyear2024-confetti span:nth-child(13) { left: 48%; }
.newyear2024-confetti span:nth-child(14) { left: 52%; }
.newyear2024-confetti span:nth-child(15) { left: 56%; }
.newyear2024-confetti span:nth-child(16) { left: 60%; }
.newyear2024-confetti span:nth-child(17) { left: 64%; }
.newyear2024-confetti span:nth-child(18) { left: 68%; }
.newyear2024-confetti span:nth-child(19) { left: 72%; }
.newyear2024-confetti span:nth-child(20) { left: 76%; }
.newyear2024-confetti span:nth-child(21) { left: 80%; }
.newyear2024-confetti span:nth-child(22) { left: 84%; }
.newyear2024-confetti span:nth-child(23) { left: 88%; }
.newyear2024-confetti span:nth-child(24) { left: 92%; }
.newyear2024-confetti span:nth-child(25) { left: 96%; }

@media screen and (max-width:750px) {
    .newyear2024-confetti span:nth-child(2) { display: none; }
    .newyear2024-confetti span:nth-child(4) { display: none; }
    .newyear2024-confetti span:nth-child(6) { display: none; }
    .newyear2024-confetti span:nth-child(8) { display: none; }
    .newyear2024-confetti span:nth-child(10) { display: none; }
    .newyear2024-confetti span:nth-child(12) { display: none; }
    .newyear2024-confetti span:nth-child(14) { display: none; }
    .newyear2024-confetti span:nth-child(16) { display: none; }
    .newyear2024-confetti span:nth-child(18) { display: none; }
    .newyear2024-confetti span:nth-child(20) { display: none; }
    .newyear2024-confetti span:nth-child(22) { display: none; }
    .newyear2024-confetti span:nth-child(24) { display: none; }
}
/* colors */
.newyear2024-confetti span:nth-child(5n+1) { background: #A78B00; }
.newyear2024-confetti span:nth-child(5n+2) { background: #fff; border: 1px solid #A78B00; }
.newyear2024-confetti span:nth-child(5n+3) { background: #A78B00; }
.newyear2024-confetti span:nth-child(5n+4) { background: #fff; border: 1px solid #A78B00; }
.newyear2024-confetti span:nth-child(5n+5) { background: #A78B00; }

/* animation-duration */
.newyear2024-confetti span:nth-child(3n+1) { animation-duration: 3s; }
.newyear2024-confetti span:nth-child(3n+2) { animation-duration: 7s; }
.newyear2024-confetti span:nth-child(3n+3) { animation-duration: 3s; }
.newyear2024-confetti span:nth-child(3n+4) { animation-duration: 4s; }
@media screen and (max-width:750px) {
    .newyear2024-confetti span:nth-child(3n+1) { animation-duration: 5s; }
    .newyear2024-confetti span:nth-child(3n+2) { animation-duration: 12s; }
    .newyear2024-confetti span:nth-child(3n+3) { animation-duration: 8s; }
    .newyear2024-confetti span:nth-child(3n+4) { animation-duration: 6s; }
}

/* animation-delay */
.newyear2024-confetti span:nth-child(5n+1) { animation-delay: 0s; }
.newyear2024-confetti span:nth-child(5n+2) { animation-delay: .5s; }
.newyear2024-confetti span:nth-child(5n+3) { animation-delay: 2s; }
.newyear2024-confetti span:nth-child(5n+4) { animation-delay: 4s; }
.newyear2024-confetti span:nth-child(5n+5) { animation-delay: 5s; }
.newyear2024-confetti span:nth-child(5n+6) { animation-delay: 1s; }
.newyear2024-confetti span:nth-child(5n+7) { animation-delay: 2s; }
.newyear2024-confetti span:nth-child(5n+8) { animation-delay: 3s; }
.newyear2024-confetti span:nth-child(5n+9) { animation-delay: 4s; }
.newyear2024-confetti span:nth-child(5n+10) { animation-delay: 5s; }
.newyear2024-confetti span:nth-child(5n+11) { animation-delay: 1s; }
/* animation */

@keyframes confetti-anim-1 {
    0% {
        top: -10%;
        transform: translateX(0) rotateX(0) rotateY(0);
    }

    100% {
        top: 100%;
        transform: translateX(20vw) rotate(1200deg) ;
    }
}

@keyframes confetti-anim-2 {
    0% {
        top: -10%;
        transform: translateX(0) rotateX(0) rotateY(0);
    }

    100% {
        top: 100%;
        transform: translateX(-20vw) rotate(1200deg);
    }
}

.js-inview {
  opacity: 0;
  transition: opacity 1s ease;
  &.is-inview {
    opacity: 1;
  }
}

.newyear2024-small {
  font-size: .8rem;
}
