@use "global" as *;

.l-header {
  
  @include media(pc) {
    
  }

  @include media(sp) {
    
  }
}

.l-header_logo {
  position: fixed;
  z-index: 2;
  @include media(pc) {
    top: 50px;
    left: 50px;
  }
  
  @include media(sp) {
    top: sp(30);
    left: sp(30);
  }
}

.l-header_logo_anchor {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  @include hoverImage($opacity: .6);
}

.l-header_logo_item {
  @include media(pc) {
    width: 85px;
  }
  
  @include media(sp) {
    width: sp(80);
  }
}

