@use "global" as *;


.l-nav {
  pointer-events: none;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  opacity: 0;
  transition: opacity .1s ease .3s;
  
  @include media(pc) {

  }
  
  @include media(sp) {
  
  }

  &.is-show {
    opacity: 1;
    pointer-events: auto;
    @include media(pc) {
    
    }
    
    @include media(sp) {
    
    }
  }
}

.l-nav_container {
  height: 100%;
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {
    padding: sp(40);
  }
}

.l-nav_logo {
  
  @include media(pc) {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @include media(sp) {
  
  }
}

.l-nav_logo_anchor {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  @include hoverImage($opacity: .5);
}

.l-nav_logo_item {
  
  @include media(pc) {
    width: 336px;
  }
  
  @include media(sp) {
    width: sp(200);
  }
}

.l-nav_main {
  @include media(pc) {
    width: 40%;
    padding-left: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  @include media(sp) {
    margin-top: sp(70);
  }
}

.l-nav_list {
  @include media(pc) {
  }
  
  @include media(sp) {
  
  }
}

.l-nav_list_item {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  
  @include media(pc) {
    font-size: 50px;
    margin-bottom: 60px;
  }
  
  @include media(sp) {
    font-size: sp(34);
  }


  & + & {
    @include media(pc) {
    
    }
    
    @include media(sp) {
      margin-top: sp(40);
    }
  }
}

.l-nav_list_anchor {
  text-decoration: none;
  color: #000;
  position: relative;
  transition: color 0.4s;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: -.8rem;
    left: -.8rem;
    padding: .8rem .8rem 0 .8rem;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-nav_list_anchor_text {
  position: relative;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}


.l-nav_subList {
  @include media(pc) {
    margin-left: .4rem;
  }
  
  @include media(sp) {
    margin-top: sp(50);
  }
}

.l-nav_subList_item {
  font-weight: 700;
  
  @include media(pc) {
    font-size: 20px;
    margin-bottom: 30px;
  }
  
  @include media(sp) {
    font-size: sp(18);
  }

  & + & {
    @include media(pc) {
    
    }
    
    @include media(sp) {
      margin-top: sp(30);
    }
  }
}

.l-nav_subList_anchor {
  text-decoration: none;
  color: #000;
  position: relative;
  transition: color 0.4s;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: -.4rem;
    padding: 0 .4rem 0 .4rem;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-nav_subList_anchor_text {
  position: relative;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-navTrigger {
  position: fixed;
  z-index: 1005;
  background: none;
  border: none;
  appearance: none;
  transition: all .4s;
  margin: 0;
  padding: 0;
  cursor: pointer;
  mix-blend-mode: difference;
  @include media(pc) {
    top: 50px;
    right: 30px;
    padding: 20px;
  }
  
  @include media(sp) {
    top: sp(30);
    right: sp(10);
    padding: sp(20);
  }
}

.l-navTrigger_container {
  display: block;
  position: relative;
  @include media(pc) {
    width: 35px;
    height: 16px;
  
  }
  
  @include media(sp) {
    width: sp(35);
    height: sp(16);
  }
}

.l-navTrigger_item {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: all .3s;
  @include media(pc) {
    height: 2px;
  }
  
  @include media(sp) {
    height: sp(2);
  }

  &:nth-of-type(1) { top: 0; }

  &:nth-of-type(2) {
    @include media(pc) { top: 7px; }
    @include media(sp) { top: sp(7); }
  }

  &:nth-of-type(3) { bottom: 0; }
}

.l-navTrigger.is-open .l-navTrigger_item:nth-of-type(1) {
  @include media(pc) { transform: translateY(7px) rotate(-45deg); }
  @include media(sp) { transform: translateY(sp(7)) rotate(-45deg); }
}
.l-navTrigger.is-open .l-navTrigger_item:nth-of-type(2) {
  opacity: 0;
}
.l-navTrigger.is-open .l-navTrigger_item:nth-of-type(3) {
  @include media(pc) { transform: translateY(-7px) rotate(45deg); }
  @include media(sp) { transform: translateY(sp(-7)) rotate(45deg); }
}



.l-navCurtain {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #D5000F;
  transform: scale(0, 1);
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  &.is-in {
    animation: lNavCurtainIn .2s cubic-bezier(.645,.045,.355,1) forwards alternate,
               lNavCurtainOut .3s cubic-bezier(.645,.045,.355,1) .4s forwards alternate;
  }

  &.is-out {
    animation: lNavCurtainInClose .2s cubic-bezier(.645,.045,.355,1) forwards alternate,
               lNavCurtainOutClose .3s cubic-bezier(.645,.045,.355,1) .4s forwards alternate;
  }
}

@keyframes lNavCurtainIn {
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  100% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}
@keyframes lNavCurtainOut {
  0% {
    transform-origin: right top;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
}

@keyframes lNavCurtainInClose {
  0% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
  100% {
    transform-origin: right top;
    transform: scale(1, 1);
  }
}
@keyframes lNavCurtainOutClose {
  0% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
}
