@use "global" as *;

html {
  background: #fff;
  color: #000;
  font-family: 'LINESeedJP', 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', sans-serif;
  overflow-x: hidden;

  @include media(pc) {
    font-size: 16px;
  }

  @include media(sp) {
    font-size: sp(16);
    width: 100vw;
  }

  &.is-fixed {
    // position: fixed;
    // overflow: hidden;
  }

}

a {
  color: inherit;
}

button {
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  background: transparent;
}
