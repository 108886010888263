@use "global" as *;

.l-footer {
  background-color: #141414;
  color: #fff;
  @include media(pc) {
    padding: 100px 32px;
  }
  
  @include media(sp) {
    padding: sp(30);
  }
}

.l-footer_upper {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-footer_upper_container {
  
  @include media(pc) {
    display: flex;
    max-width: 1080px + 32px * 2;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
  }
  
  @include media(sp) {
  
  }
}

.l-footer_nav {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
    border-bottom: 1px solid #333;
    margin-bottom: sp(30);
    padding-bottom: sp(10);
  }
}

.l-footer_nav_main {
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  @include media(pc) {
  
  }
  
  @include media(sp) {
    flex-wrap: wrap;
  }
}

.l-footer_nav_main_item {
  
  @include media(pc) {
    font-size: 18px;
  }
  
  @include media(sp) {
    width: 45%;
    font-size: sp(18);
    margin-bottom: 30px;
  
  }

  & + & {
    @include media(pc) {
      margin-left: 30px;
    }
    
    @include media(sp) {
    
    }
  }
}

.l-footer_nav_main_anchor {
  color: #fff;
  text-decoration: none;
  position: relative;
  transition: color 0.4s;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: -.3rem;
    left: -.4rem;
    padding: .3rem .4rem 0 .4rem;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #000;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-footer_nav_main_anchor_text {
  position: relative;
}

.l-footer_nav_sub {
  display: flex;
  @include media(pc) {
    margin-top: 30px;
  }
  
  @include media(sp) {
    flex-wrap: wrap;
  }
}

.l-footer_nav_sub_item {
  
  @include media(pc) {
    font-size: 14px;
  }
  
  @include media(sp) {
    font-size: sp(14);
    margin-right: sp(30);
    margin-bottom: sp(20);
  }

  & + & {
    @include media(pc) {
      margin-left: 30px;
    }
    
    @include media(sp) {
    }
  }
}

.l-footer_nav_sub_anchor {
  color: #787878;
  text-decoration: none;
  position: relative;
  transition: color 0.4s;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: -.2rem;
    left: -.4rem;
    padding: .2rem .4rem 0 .4rem;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-footer_nav_sub_anchor_text {
  position: relative;
  line-height: 1.4;
}

.l-footer_link {
  
  @include media(pc) {
    border-left: 1px solid #323232;
    margin-left: 40px;
    padding-left: 80px;
  }
  
  @include media(sp) {
  
  }
}

.l-footer_link_title {
  color: #787878;
  
  @include media(pc) {
    margin-bottom: 20px;
  }
  
  @include media(sp) {
  
  }
}

.l-footer_link_anchor {
  color: #787878;
  text-decoration: none;
  display: inline-block;
  line-height: 1.6;
  text-decoration: none;
  position: relative;
  transition: color 0.4s;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: -.2rem;
    left: -.4rem;
    padding: .2rem .4rem 0 .4rem;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #000;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  @include media(pc) {
    font-size: 14px;
  }
  
  @include media(sp) {
    font-size: sp(14);
    margin-top: sp(20);
  }
}

.l-footer_link_anchor_text {
  position: relative;
}

.l-footer_lower {
  
  @include media(pc) {
    margin-top: 80px;
  }
  
  @include media(sp) {
  
  }
}

.l-footer_lower_container {
  
  @include media(pc) {
    display: flex;
    justify-content: space-between;
    max-width: 1080px + 32px * 2;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
    align-items: flex-end;
  }
  
  @include media(sp) {
  
  }
}

.l-footer_info {
  
  @include media(pc) {
    display: flex;  
  }
  
  @include media(sp) {
    margin-top: sp(50);
  }
}

.l-footer_info_logo {
  
  @include media(pc) {
    width: 200px;
  }
  
  @include media(sp) {
    width: sp(200);
  }
}

.l-footer_info_address {
  color: #787878;
  line-height: 1.6;
  @include media(pc) {
    font-size: 12px;
    margin-left: 20px;
  }
  
  @include media(sp) {
    font-size: sp(14);
    margin-top: sp(20);
  }
}

.l-footer_copy {
  
  @include media(pc) {
  
  }
  
  @include media(sp) {
    margin-top: sp(20);
  }
}

.l-footer_copy_item {
  color: #787878;
  font-family: 'Josefin Sans', sans-serif;
  @include media(pc) {
    font-size: 14px;
  }
  
  @include media(sp) {
    font-size: sp(14);
  }
}
