@use "global" as *;

.m-wrapper {
  @include media(pc) {
    padding-top: 250px;
  }
  
  @include media(sp) {
    padding-top: sp(150);
  }
}

.m-container {

  @include media(pc) {
    max-width: 1080px + 32px * 2;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media(sp) {
    padding-left: sp(30);
    padding-right: sp(30);
  }
}

.m-heading {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  letter-spacing: .4rem;
  background-image: url('/_/media/module/heading_line.svg');
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  @include media(pc) {
    font-size: 60px;
    padding-left: 80px;
    line-height: .55;
    padding-top: 1.2rem;
    margin-bottom: 140px;
  }
  
  @include media(sp) {
    font-size: sp(36);
    padding-left: sp(60);
    line-height: .7;
    padding-top: .7rem;
    margin-bottom: sp(60);
  }
}

.m-lead {
  @include media(pc) {
    line-height: 2.5;
    font-weight: 700;
    font-size: 18px;
  }
  
  @include media(sp) {
    line-height: 2.2;
    font-weight: 500;
    font-size: sp(15);
  }
}

.m-hr {
  @include media(pc) {
    border: 0;
    border-top: 1px solid #ddd;
    margin: 70px 0;
  }
  
  @include media(sp) {
  
  }
}

.m-bilingualHeading {
  @include media(pc) {
    margin-bottom: 40px;
  }

  @include media(sp) {
    margin-bottom: sp(30);
  }

  &::after {
    content: '';
    display: block;
    background-color: #C8161D;
    @include media(pc) {
      width: 80px;
      height: 2px;
      margin-top: 40px;
    }

    @include media(sp) {
      width: sp(60);
      height: sp(2);
      margin-top: sp(20);

    }
  }
}

.m-bilingualHeading_sub {
  color: #C8161D;
  font-weight: 700;
  @include media(pc) {
    font-size: 20px;
    margin-bottom: 10px;
  }

  @include media(sp) {
    font-size: sp(14);
    margin-bottom: sp(10);
  }
}
.m-bilingualHeading_main {
  line-height: 1.4;
  font-weight: 700;
  @include media(pc) {
    font-size: 32px;
  }

  @include media(sp) {
    font-size: sp(22);
  }
}

.m-raidusBtn {
  display: flex;
  flex-wrap: wrap;
  @include media(pc) {

  }

  @include media(sp) {
    justify-content: center;
  }
}

.m-raidusBtn_item {
  color: #fff;
  text-decoration: none;
  position: relative;
  transition: color 0.4s;
  background-color: #C8161D;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;

  @include media(pc) {
    height: 50px;
    border-radius: 25px;
  }

  @include media(sp) {
    height: sp(50);
    border-radius: sp(25);
    font-size: sp(15);
    width: 96%;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
    @include media(pc) {
      border-radius: 25px;
    }

    @include media(sp) {
      border-radius: sp(25);
    }
  }

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  & + & {
    @include media(pc) {
      margin-left: 20px;
    }

    @include media(sp) {
      margin-top: sp(20);
    }
  }
}

.m-raidusBtn_item_text {
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-borderHeading {
  font-weight: 700;
  display: flex;
  line-height: 1.6;
  @include media(pc) {
    margin-bottom: 20px;
  }

  @include media(sp) {
    margin-bottom: sp(10);
  }

  &::before {
    content: '';
    background-color: #C8161D;
    margin-right: 1rem;
    @include media(pc) {
      width: 20px;
      height: 2px;
      margin-top: 1rem;
    }

    @include media(sp) {
      width: sp(20);
      height: sp(2);
      margin-top: .8rem;
    }
  }
}

.m-borderHeading_text {
  flex: 1;
  @include media(pc) {
    font-size: 20px;
  }

  @include media(sp) {
    font-size: sp(18);
  }
}

.m-pagetop {
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
  pointer-events: none;
  position: fixed;
  opacity: 0;
  transition: opacity 0.5s;
  mix-blend-mode: difference;
  @include media(pc) {
    width: 70px;
    height: 70px;
    right: 10px;
    bottom: 30px;
  }

  @include media(sp) {
    display: none;
  }
  &:hover {
    .m-pagetop_icon {
      @include media(pc) {
        transform: translateY(-10px);
      }
    }
  }

  &.is-visible {
    pointer-events: auto;
    opacity: 1;
    @include media(pc) {

    }

    @include media(sp) {

    }
  }
}

.m-pagetop_icon {
  transition: transform .2s ease;
  @include media(pc) {
    width: 20px;
  }

  @include media(sp) {

  }
}


.m-stalker {
  @include media(pc) {
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    opacity: 0;

    &.is-stalking {
      opacity: 1;
    }

    &.is-stalking-hover {
      .m-stalker_item {
        border: 1px solid $color-primary;
        color: $color-primary;
      }

      .m-stalker_ring {
        filter: invert(22%) sepia(76%) saturate(3896%) hue-rotate(345deg) brightness(77%) contrast(106%);
        animation: 1.5s linear infinite stalkingRotaion;
      }
    }

    &.is-open {
      left: 50% !important;
      top: 50% !important;
      transition: left .5s ease, top .5s ease;
      animation: 1s ease .5s worksOpen forwards;
      opacity: 1;
      .m-stalker_item {
        color: $color-primary;
        border: 1px solid $color-primary;
        animation: 1s ease .5s worksOpenItem forwards;
      }

      .m-stalker_ring {
        filter: invert(22%) sepia(76%) saturate(3896%) hue-rotate(345deg) brightness(77%) contrast(106%);
      }
    }
  }

  @include media(sp) {
    display: none;
  }
}

@keyframes stalkingRotaion {
  0% { transform: rotate(0);}
  100% { transform: rotate(360deg); }
}

@keyframes worksOpen {
  99% {
    transform: translate(-50%, -50%) scale(50);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(50);
    opacity: 0;
  }
}


@keyframes worksOpenItem {
  10% {
    background-color: $color-primary;
  }
  100% {
    background-color: $color-primary;
  }
}



.m-stalker_item {
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #AEAEAE;
  font-size: 20px;
  font-weight: 300;
  width: 100px;
  height: 100px;
  border: 1px solid #A5A5A5;
  border-radius: 50%;
  transition: border .3s ease, color .3s ease;
  background-color: rgba(255,255,255,.5);
  font-weight: 700;
}

.m-stalker_ring {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 144px;
  height: 144px;
  margin-left: -72px;
  margin-top: -72px;
  animation: 3s linear infinite stalkingRotaion;
}


.m-stalkerSp {
  @include media(pc) {
    display: none;
  }

  @include media(sp) {
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    opacity: 0;
    &.is-open {
      animation: 1.2s ease worksOpenSp forwards;
      opacity: 1;

      .m-stalkerSp_item {
        animation: 1.2s ease worksOpenItemSp forwards;
      }
    }
  }
}

.m-stalkerSp_item {
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: sp(20);
  font-weight: 300;
  width: sp(100);
  height: sp(100);
  border-radius: 50%;
  transition: border .3s ease, color .3s ease;
  background-color: rgba(255,255,255,.5);
  font-weight: 700;
  color: $color-primary;
  border: 1px solid $color-primary;
}

.m-stalkerSp_ring {
  position: absolute;
  top: 50%;
  left: 50%;
  width: sp(136);
  height: sp(136);
  margin-left: sp(-68);
  margin-top: sp(-68);
  filter: invert(22%) sepia(76%) saturate(3896%) hue-rotate(345deg) brightness(77%) contrast(106%);
  animation: 1.5s linear infinite stalkingRotaion;
}

@keyframes worksOpenItemSp {
  50% {
    background-color: $color-primary;
  }
  100% {
    background-color: $color-primary;
  }
}

@keyframes worksOpenSp {
  50% {
    left: 50%;
    top: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  99% {
    transform: translate(-50%, -50%) scale(15);
    left: 50%;
    top: 50%;
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(15);
    opacity: 0;
    left: 50%;
    top: 50%;
  }
}
