@use '../variable' as *;

@mixin media ($breakpoint, $mq: 'normal') {
  @if ($breakpoint == 'pc' or $breakpoint == 'sp') {
    @media #{map-get($breakpoints, $breakpoint)} {
      @content;
    }
  } @else if ($mq == 'normal') {
    @media print, screen and (min-width:#{$breakpoint-sp + 1}px) and (max-width:$breakpoint) {
      @content;
    }
  } @else if ($mq == 'min') {
    @media print, screen and (min-width:$breakpoint) {
      @content;
    }
  } @else if ($mq == 'max') {
    @media print, screen and (max-width:$breakpoint) {
      @content;
    }
  } @else {
    @media print, screen and (min-width:#{$breakpoint}) and (max-width:$mq) {
      @content;
    }
  }
}


@mixin hoverImage($opacity: .7, $duration: .3s) {
  transition: opacity $duration ease;
  &:hover {
    opacity: $opacity;
  }
}

@mixin currentAnimationBlur($delay: .5) {
  opacity: 0;
  filter: blur(5px);
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: ease;
  transition-delay: #{$delay}s;
  

  &.is-current {
    opacity: 1;
    filter: blur(0);
    @include media(pc) {
    }
    
    @include media(sp) {
    
    }
  }
}