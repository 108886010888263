@use "global" as *;


.l-contact {
  background-image: url('/_/media/layout/contact_bg.png');
  background-size: cover;
  filter: grayscale(100%);
  @include media(pc) {
    padding: 150px 0;
  }
  
  @include media(sp) {
  
  }

  &.aos-animate {
    filter: grayscale(0);
  }
}

.l-contact_container {
  
  @include media(pc) {
    max-width: 1080px + 32px * 2;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @include media(sp) {
    padding: sp(60) sp(30);
  }
}

.l-contact_message {
  line-height: 2;
  font-weight: 700;
  color: #fff;
  text-align: center;
  @include media(pc) {
    font-size: 22px;
    width: 50%;
  }
  
  @include media(sp) {
  }
}

.l-contact_btn {
  text-decoration: none;
  color: #fff;
  position: relative;
  padding-top: .4rem;
  transition: color 0.4s;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 40%;
  border: 1px solid #fff;
  text-align: center;
  justify-content: center;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.3);
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s;
  }

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  
  @include media(pc) {
    height: 90px;
    font-size: 32px;
    margin-left: 40px;
  }
  
  @include media(sp) {
    height: sp(60);
    width: 100%;
    margin-top: sp(20);
    font-size: sp(20);
  }
}
